// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/first_back.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".first[data-v-2e01aacd]{width:100%;min-width:320px;height:100%;position:relative;overflow:hidden}.first .myVideo[data-v-2e01aacd]{bottom:0;right:0;background-position:50%;background-size:contain;-o-object-fit:cover;object-fit:cover}.first .back[data-v-2e01aacd],.first .myVideo[data-v-2e01aacd]{position:absolute;top:0;width:100%;height:100%}.first .back[data-v-2e01aacd]{left:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover;background-color:rgba(0,0,0,.85)}", ""]);
// Exports
module.exports = exports;

<template>
  <div class="third">
    <div class="box">
      <img class="third_img" src="~assets/images/third.png" alt="">
      <div class="back">
        <Bc :class="[{slide_left: play}, {slide_toleft: toplay}]" />
        <Nfc :class="[{slide_right: play}, {slide_toright: toplay}]" />
      </div>
      <div class="bottom">
        <img class="third_text" src="~assets/images/third_text.png" alt="">
      </div>
    </div>
    <div class="use_back" align="center">
      <img class="use" src="~assets/images/use.png" alt="">
    </div>
    <!-- <img class="use" src="~assets/images/use.png" alt=""> -->
  </div>
</template>

<script>
import Bc from "components/anime/m_bc"
import Nfc from "components/anime/m_nfc"

export default {
  name: "M_third",
  props: ["play", "restart", "toplay", "torestart", "swiperToTop", "swiperToBottom"],
  data() {
    return {
      
    }
  },
  components: {
    Bc,
    Nfc
  }
}
</script>

<style lang="less" scoped>
  @-webkit-keyframes left{
    from {-webkit-transform: translate(-150px, -150px);opacity: 0;}
    to {-webkit-transform: translate(0, 0);opacity: 1;}
  }
  .slide_left{
    animation: left 1s ease-out normal forwards;
    -moz-animation: left 1s ease-out normal forwards;
    -webkit-animation: left 1s ease-out normal forwards;
    -o-animation: left 1s ease-out normal forwards;
  }
  @-webkit-keyframes toleft{
    from {-webkit-transform: translate(0, 0);opacity: 1;}
    to {-webkit-transform: translate(-150px, -150px);opacity: 0;}
  }
  .slide_toleft{
    animation: toleft 1s ease-out normal;
    -moz-animation: toleft 1s ease-out normal;
    -webkit-animation: toleft 1s ease-out normal;
    -o-animation: toleft 1s ease-out normal;
  }
  @-webkit-keyframes right{
    from {-webkit-transform: translate(150px, -150px);opacity: 0;}
    to {-webkit-transform: translate(0, 0);opacity: 1;}
  }
  .slide_right{
    animation: right 1s ease-out normal forwards;
    -moz-animation: right 1s ease-out normal forwards;
    -webkit-animation: right 1s ease-out normal forwards;
    -o-animation: right 1s ease-out normal forwards;
  }
  @-webkit-keyframes toright{
    from {-webkit-transform: translate(0, 0);opacity: 1;}
    to {-webkit-transform: translate(150px, -150px);opacity: 0;}
  }
  .slide_toright{
    animation: toright 1s ease-out normal;
    -moz-animation: toright 1s ease-out normal;
    -webkit-animation: toright 1s ease-out normal;
    -o-animation: toright 1s ease-out normal;
  }
  .third{
    // max-width: 480px;
    min-width: 320px;
    width: 100%;
    height: 100%;
    // position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: flex-end;
    .box{
      width: 100%;
      height: calc(100vh);
      position: relative;
      .third_img{
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 3rem;
      }
      .back {
        width: 100%;
        height: 100%;
      }
    }
    .bottom{
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      // margin-top: 589px;
      width: 14.28rem;
      height: 2.6rem;
      background: url(~assets/images/third_back1.png) no-repeat center center;
      background-size: cover;
      display: flex;
      justify-content: center;
      .third_text{
        width: 7.5rem;
      }
    }
    .use_back {
      width: 100%;
      height: 2.04rem;
      background: url(~assets/images/four_back.png) no-repeat;
      // background-size: cover;
      position: relative;
      // bottom: -378px;
      // left: 50%;
      // transform: translateX(-50%);
      text-align: center;
      z-index: 2;
      .use{
        margin-top: -0.18rem;
        width: 6.48rem;
      }
    }
  }
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/four_back.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".four[data-v-1eb2da60]{min-width:320px;width:100%;padding-bottom:1.2rem;bottom:3.5rem}.four .four_back[data-v-1eb2da60]{position:absolute;width:100%;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.four .four-box[data-v-1eb2da60]{width:100%;text-align:center;overflow:hidden;position:relative;z-index:1}.four .four-box .title[data-v-1eb2da60]{font-size:.48rem;color:#000;font-weight:700;margin:0 auto;margin-top:1.2rem;margin-bottom:.72rem}.four .four-box .swiper-container-item[data-v-1eb2da60]{max-width:480px;width:7.4rem;margin:0 auto;overflow:hidden}.four .four-box .swiper-container-item .swiper-wrapper[data-v-1eb2da60]{width:100%}.four .four-box .swiper-container-item .swiper-wrapper .swiper-slide[data-v-1eb2da60]{width:3.6rem;margin-bottom:.24rem}.four .four-box .swiper-container-item .swiper-wrapper .swiper-slide .item-img[data-v-1eb2da60]{width:3.6rem;height:2rem}", ""]);
// Exports
module.exports = exports;

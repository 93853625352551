<template>
  <div class="footer">
    <div class="footer-text">
      <router-link to="/policy">{{$t('footer.privacy_policy')}}</router-link>
      <router-link to="/service">{{$t('footer.term_of_service')}}</router-link>
      <span>{{$t('footer.contact')}}</span>
    </div>
    <div class="footer-img">
      <a class="item-img bilibili" href="https://space.bilibili.com/106874236" target="__blank"></a>
      <a class="item-img douyin" href="https://www.douyin.com/" target="__blank"></a>
      <div class="item-img weixin"></div>
      <a class="item-img weibo" href="https://weibo.com/x1art/" target="__blank"></a>
    </div>
    <a href="https://beian.miit.gov.cn/" target="_blank" class="bei">浙ICP备20027847号-3</a>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {

    }
  },
}
</script>

<style lang="less" scoped>
  .footer{
    min-width: 1200px;
    // min-height: 800px;
    width: 100%;
    position: relative;
    z-index: 1;
    height: 3.3375rem;
    text-align: center;
    background: linear-gradient(to bottom, #000000 50%, #171923 50%);
    color: white;
    overflow: hidden;
    .footer-text{
      display: flex;
      justify-content: space-between;
      width: 5.1rem;
      height: 0.3rem;
      margin: 0 auto;
      margin-top: 0.6374rem;
      font-size: 0.225rem;
      a {
        color: white;
        text-decoration: none;
      }
    }
    .footer-img{
      width: 2.9rem;
      height: 0.5rem;
      margin:0 auto;
      margin-top: 0.9625rem;
      display: flex;
      justify-content: space-between;
      .item-img{
        width: 0.5rem;
        height: 0.5rem;
      }
      .bilibili{
        background: url(~assets/images/bilibili.png) no-repeat;
        background-size: cover;
      }
      .douyin{
        background: url(~assets/images/douyin.png) no-repeat;
        background-size: cover;
      }
      .weixin{
        background: url(~assets/images/wechat.png) no-repeat;
        background-size: cover;
      }
      .weibo{
        background: url(~assets/images/weibo_logo.png) no-repeat;
        background-size: cover;
      }
    }
    a {
        color: white;
        text-decoration: none;
      }
    .bei{
      display: block;
      font-size: 0.175rem;
      margin: 0 auto;
      margin-top: 0.3rem;
    }
  }
</style>

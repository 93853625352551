// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/bilibili.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/images/douyin.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/images/wechat.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/images/weibo_logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".footer[data-v-19995d58]{min-width:320px;width:100%;height:3.5rem;text-align:center;background:#000;color:#fff;overflow:hidden;position:relative;z-index:1}.footer .footer-img[data-v-19995d58]{width:4.64rem;height:.8rem;margin:0 auto;margin-top:1.2rem;display:flex;justify-content:space-between}.footer .footer-img .item-img[data-v-19995d58]{width:.8rem;height:.8rem}.footer .footer-img .bilibili[data-v-19995d58]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover}.footer .footer-img .douyin[data-v-19995d58]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:cover}.footer .footer-img .weixin[data-v-19995d58]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:cover}.footer .footer-img .weibo[data-v-19995d58]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat;background-size:cover}.footer a[data-v-19995d58]{color:#fff;text-decoration:none}.footer .bei[data-v-19995d58]{display:block;font-size:.28rem;margin:0 auto;margin-top:.48rem}", ""]);
// Exports
module.exports = exports;

<template>
  <div class="four">
    <div class="four_back"></div>
    <div class="four-box">
      <!-- <img class="use" src="~assets/images/use.png" alt=""> -->
      <div class="title">{{$t('four.ip')}}</div>
      <!-- <div class="desc">{{$t('four.partner')}}</div> -->
      <div class="item-box">
        <div class="item" v-for="(item, index) in list">
          <img class="item-img" :src="item.image" alt="">
          <!-- <p class="item-title">{{getText(item,'title')}}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Four",
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getBanner()
  },
  methods: {
    async getBanner () {
      const { data: res } = await this.$http.get("v1/banner/all")
			if (res.code !== 0) return this.$message.error(res.message)
      this.list = res.data
    },
  }
}
</script>

<style lang="less" scoped>
  .four{
    min-width: 1200px;
    min-height: 800px;
    width: 100%;
    height: calc(100vh);
    position: relative;
    .four_back{
      // top: -5px;
      position: absolute;
      width: 100%;
      height: 100% ;
      background: url(~assets/images/four_back.png) no-repeat center;
      background-size: cover;
    }
    .four-box{
      width: 100%;
      // height: 100%;
      text-align: center;
      overflow: hidden;
      position: relative;
      z-index: 1;
      .title{
        font-size: 0.75rem;
        color: black;
        font-weight: bold;
        margin: 0 auto;
        margin-top: 1.4125rem;
      }
      .desc{
        font-size: 0.3rem;
        color: #808080;
        margin: 0 auto;
        margin-top: 0.7rem;
      }
      .item-box{
        width: 13.5375rem;
        margin: 0 auto;
        margin-top: 2.15rem;
        margin-bottom: 0.15rem;
        display: flex;
        flex-wrap: wrap;
        .item{
          width: 2.25rem;
          height: 1.25rem;
          margin-right: 1.5125rem;
          margin-bottom: 1.5rem;
          flex: 2.5;
          .item-img{
            width: 2.25rem;
          }
          .item-title{
            font-size: 0.225rem;
            color: black;
          }
        }
      }
      .item:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
</style>

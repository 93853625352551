<template>
  <div>
    <navbar />
    <div class="content">
      <div class="title">网站服务条款</div>
      <h2>尊敬的用户：</h2>
      <h2>欢迎您成为本网站用户。在第一次登陆前请您仔细阅读如下服务条款。</h2>
      <p>本服务协议双方为x1art.com（以下称作“本网站”）与本网站用户，本服务协议具有合同效力。您确认本服务协议后，本服务协议即在您和本网站之间产生法律效力。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本网站咨询。</p>
      <p>本隐私权政策将会进行不定时更新。 无论您事实上是否在注册之前认真阅读了本服务协议，只要您点击协议正本下方的"注册"按钮并按照本网站注册程序成功注册为用户，或者第一次成功登陆本网站后，您的行为仍然表示您同意并签署了本服务协议。</p>
      <h2>1.	本网站服务条款的确认和接纳</h2>
      <p>本网站各项服务的所有权和运作权归本网站拥有。</p>
      <h2>2．用户必须：</h2>
      <p>
        <ul>
          <li>自行配备上网的所需设备，包括个人电脑、调制解调器或其他必备上网装置。</li>
          <li>自行负担个人上网所支付的与此服务有关的电话费用、网络费用。</li>
        </ul>
      </p>
      <h2>3. 用户的言行不得违反相关的法律法规的规定，不得在本网站及交易平台上发布下列违法信息：</h2>
      <p>
        <ul>
          <li>反对宪法所确定的基本原则的；</li>
          <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>
          <li>损害国家荣誉和利益的；</li>
          <li>煽动民族仇恨、民族歧视，破坏民族团结的；</li>
          <li>破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
          <li>散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
          <li>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
          <li>侮辱或者诽谤他人，侵害他人合法权益的；</li>
          <li>含有法律、行政法规禁止的其他内容的。</li>
        </ul>
      </p>
      <h2>4．有关个人资料</h2>
      <p>用户同意：
        <ul>
          <li>提供及时、详尽及准确的个人资料。</li>
          <li>同意接收来自本网站的信息。</li>
          <li>不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。</li>
          <li>本网站不公开用户的姓名、地址、电子邮箱和笔名，以下情况除外：<br />
            a.用户授权本网站透露这些信息；<br />
            b.相关的法律或政府主管部门要求本网站提供用户的个人资料，如果用户提供的资料包含有不正确的信息，本网站保留结束用户使用本网站信息服务资格的权利。
          </li>
          <li>用户在注册时应当选择稳定性及安全性相对较好的电子邮箱，并且同意接受并阅读本网站发往用户的各类电子邮件。如用户未及时从自己的电子邮箱接受电子邮件或因用户电子邮箱或用户电子邮件接收及阅读程序本身的问题使电子邮件无法正常接收或阅读的，只要本网站成功发送了电子邮件，应当视为用户已经接收到相关的电子邮件。电子邮件在发信服务器上所记录的发出时间视为送达时间。</li>
        </ul>
      </p>
      <h2>5．服务条款的修改</h2>
      <p>本网站有权在必要时修改服务条款，本网站服务条款一旦发生变动，将会在重要页面上提示修改内容。如不同意所改动的内容，用户可以主动取消获得的本网站信息服务。如果用户继续享用本网站信息服务，则视为接受服务条款的变动。本网站保留随时修改服务而不需通知用户的权利；如需中断服务，本网站将尽可能提前告知用户。本网站行使修改或中断服务的权利，不需对用户或第三方负责。</p>
      <h2>6．用户隐私制度</h2>
      <p>尊重用户个人隐私是本网站的一项基本政策。所以，本网站一定不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本网站中的非公开内容，以下情况除外：</p>
      <p>
        <ul>
          <li>遵守有关法律规定，或遵从本网站合法服务程序；</li>
          <li>保持维护本网站的合法权益（包括但不限于本网站的商标所有权等）；</li>
          <li>不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。</li>
          <li>在紧急情况下竭力维护用户个人和社会大众的隐私安全；</li>
          <li>按照相关政府主管部门的要求；</li>
          <li>用于本网站相关政策的相关条款所述的其它目的。</li>
        </ul>
      </p>
      <h2>7. 用户的帐号、密码和安全性</h2>
      <p>任何人一旦注册成功/第一次登陆成为用户，将得到一个密码和帐号，用户须自行保管好自己的帐号并确保密码安全。如因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，本网站有权拒绝承担任何责任。此外，用户须对其帐户中的所有活动和事件负全责。用户可随时根据指示改变密码。用户若发现任何非法使用其帐号或存在安全漏洞的情况，应立即通知本网站。</p>
      <h2>8. 拒绝提供担保</h2>
      <p>用户明确同意信息服务的使用由用户个人承担风险。本网站不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保，但会在能力范围内，避免出错。若因硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本网站不负任何责任。</p>
      <h2>9. 有限责任</h2>
      <p>本网站对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害来自：不正当使用本网站服务，或用户传送的信息不符合规定等。这些行为都有可能导致本网站形象受损，所以本网站事先提出这种损害的可能性，同时会尽量避免这种损害的发生。</p>
      <h2>10. 信息的储存及限制</h2>
      <p>本网站有判定用户的行为是否符合本网站服务条款的要求和精神的权利，如果用户违背本网站服务条款的规定，本网站有权随时中断或终止其服务的帐号而不承担违约责任。</p>
      <h2>11. 用户管理</h2>
      <p>用户必须遵循：
        <ul>
          <li>使用信息服务不作非法用途；</li>
          <li>不干扰或混乱网络服务；</li>
          <li>遵守所有使用服务的网络协议、规定、程序和惯例。用户的行为准则是以因特网法规，政策、程序和惯例为根据的。</li>
        </ul>
      </p>
      <h2>12．保障</h2>
      <p>用户同意保障和维护本网站全体成员的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给本网站或任何其他第三人造成损失，用户需承担因此造成的损害赔偿责任（包括但不限于：用户使用超出服务范围引起的律师费用，违反服务条款的损害补偿费用，其它人使用用户的电脑、帐号和其它知识产权的追索费等）。</p>
      <h2>13．结束服务</h2>
      <p>用户或本网站可随时根据实际情况中断一项或多项服务。本网站不需对任何个人或第三方负责而随时中断服务。用户若反对任何服务条款的建议或对后来的条款修改有异议，或对本网站服务不满，用户可以行使如下权利：
        <ul>
          <li>不再使用本网站信息服务；</li>
          <li>通知本网站停止对该用户的服务。</li>
        </ul>
      </p>
      <p>结束用户服务后，用户使用本网站服务的权利马上中止。从那时起，用户没有权利要求，本网站也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。</p>
      <h2>14．通告</h2>
      <p>所有发给用户的通告都可通过重要页面的公告或电子邮件或常规的信件传送。服务条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。</p>
      <h2>15．信息内容的所有权</h2>
      <p>本网站定义的“信息内容”包括：文字、软件、声音、相片、录象、图表；在广告中全部内容；本网站为用户提供的其它信息。所有这些内容受我国《著作权法》、《商标法》、《物权法》等相关法律法规的保护。所以，用户只能在本网站和广告商授权下才能使用这些内容 ，而不能擅自复制、再造、传播这些内容、或创造与内容有关的派生产品。</p>
      <h2>16．法律</h2>
      <p>本网站信息服务条款要与中华人民共和国的法律解释一致。用户和本网站一致同意服从本网站所在地有管辖权的人民法院管辖。如发生本网站服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户的约束力。</p>
      <h2>17．其它</h2>
      <p>本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利</p>
      <p>如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本协议中的标题仅为方便而设，在解释本协议时应被忽略。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本协议最终解释权归属X1ART网站。</p>
    </div>
    <Footer />
  </div>
</template>

<script>
import navbar from "components/m_navbar.vue"
import Footer from "@/components/M_footer.vue"

export default {
  components: {
    navbar,
    Footer,
  },
  created(){
    if (!this._isMobile()) {
      this.$router.replace({ name: 'Service' })
    } 
  },
  methods: {
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)      
      return flag;
    },
  }
}
</script>

<style lang="less" scoped>
.content{
  padding: 0 10px;
  margin: 0 auto;
  max-width:1280px; 
  color: white;
  ul{
    margin-bottom:0;
  }
  .title {
    text-align: center;
    margin: 30px auto;
    font-size: 30px;
  }
  p{
    font-size: 16px;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 20px;
    margin: 20px 0;
  }
}
</style>

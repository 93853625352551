<template>
  <div class="lottie_nfc">
    <lottie :options="defaultOptions" v-on:animCreated="handleAnimation" />
  </div>
</template>
<script>
import * as animationData from "assets/json/nfc.json"

export default {
  data() {
    return {
      defaultOptions: { 
        animationData: animationData.default
      },
      anim: {}
    }
  },
  created() {
    animationData.assets.forEach((item, index) => {
      item.u = ''
      item.p = require(`assets/images/nfc_lottie/nfc_${index}.png`)
    })
  },
  methods: {
    handleAnimation: function(anim) {
        this.anim = anim;
    },
  }
}
</script>

<style lang="less" scoped>
.lottie_nfc{
  width: 3rem;
  height: 3rem;
  position: absolute;
  left: 4rem;
  top: 6rem;
  z-index: 1;
}
</style>

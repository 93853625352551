module.exports = {
  home: {
    index: '首页',
    product: '产品中心',
    product_safety_guarantee: '产品安全保障',
    ip: 'IP授权',
  },
  second: {
    role_profile: '角色简介',
    product_detail: '产品详情',
    material: '材质',
    limit: '限量 {num} 体',
    buy: '立即购买',
    code: '作品编码',
    scale: '比例',
    dimensions: '产品尺寸',
    weight: '产品重量',
    box: '包装箱A尺寸',
    assemble: '组装说明书',
  },
  four: {
    ip: 'IP授权许可',
    partner: '我们和各大IP公司都有良好的合作关系。在未来，我们将给大家带来更多的IP角色！',
  },
  footer: {
    privacy_policy: '隐私政策',
    term_of_service: '服务条款',
    contact: '联系我们',
  }
 }
<template>
  <div class="first">
    <video class="myVideo" loop muted data-autoplay>
        <source src="~assets/1.mp4" type="video/mp4">
    </video>
    <div class="back">
      <lottie :options="defaultOptions" v-on:animCreated="handleAnimation"/>
    </div>
    </div>
    
  </div>
</template>

<script>
import * as animationData from "assets/json/home.json"

export default {
  name: "M_first",
  data() {
    return {
      defaultOptions: { 
        animationData: animationData.default
      },
      anim: {}
    }
  },
  created() {
    animationData.assets.forEach((item, index) => {
      item.u = ''
      item.p = item.p ? require(`assets/images/home_lottie/img_${index}.png`) : ''
    })
  },
  methods: {
    handleCommand(command) {
      this.$store.commit('change', command)
    },
    handleAnimation: function(anim) {
        this.anim = anim;
    },
  }
}
</script>

<style lang="less" scoped>
.first {
  width: 100%;
  // max-width: 480px;
  min-width: 320px;
  height: 100%;
  position: relative;
  overflow: hidden;
  .myVideo {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height:100%;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
  }
  .back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(~assets/images/first_back.png) no-repeat;
    background-size: cover;
    background-color: rgba(0,0,0,.85);
  }
}
</style>

<template>
  <div class="second" v-if="product" :style="{backgroundImage: 'url(' + product.image + ')'}">
    <div class="backbox"></div>
    <div class="probox">
      <div class="pro">
        <div class="videobox">
          <!-- <video class="video" :src="product.video" muted autoplay loop></video> -->
          <img class="center-img" :src="product.image" alt="">
        </div>
        <div class="titlebox">
          <span class="title">{{getText(product, 'title')}}</span>
          <!-- <span class="pricebox">售价 ¥ <span class="price">{{product.price}}</span></span> -->
          <span class="pricebox">售价 ¥ <span class="price">TBD</span></span>
        </div>
        <div class="desc">
          {{getText(product, 'desc')}}
        </div>
        <div class="swiper-container-content">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="itembox" v-html="getText(product, 'content')">{{getText(product, 'content')}}</div>
            </div>
            <div class="swiper-slide">
              <div class="itembox">
                <i18n path="second.limit" tag="p" class="xiangqing">
                  <!-- <span class="limit-num" place="num">{{product.limit_num}}</span> -->
                  <span class="limit-num" place="num">TBD</span>
                </i18n>
                <p class="xiangqing">{{product.specification}}</p>
                <p class="xiangqing">{{product.material}}</p>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in productList" :key="index">
          <img class="proimg" :src="item.cover" alt="">
        </div>
      </div>
      <div class="xia jiantou"></div>
    </div>
  </div>
</template>

<script>
// 6.5.0版本
import Swiper from "swiper/swiper-bundle.min.js"
import "swiper/swiper-bundle.min.css"
import "assets/css/style.css"

export default {
  name: "M_second",
  data() {
    return {
      productList: [],
      product: {},
      like: 0,
      dianzan: 0,
      proSwiper: {},
      conSwiper: {},
      realIndex: 0,
    }
  },
  created () {
    this.getProduct()
  },
  methods: {
    async getProduct () {
      const { data: res } = await this.$http.get("v1/product/all")
			if (res.code !== 0) return this.$message.error(res.message)
      this.productList = res.data
      this.productList.forEach((item, index) => {
        item['like_'+ item.id] = localStorage.getItem('like_'+ item.id) == 1 ? localStorage.getItem('like_'+ item.id) : 0
        item['dianzan_'+ item.id] = localStorage.getItem('dianzan_'+ item.id) == 1 ? localStorage.getItem('dianzan_'+ item.id) : 0
      });
      this.product = this.productList[this.realIndex]
      this.$nextTick(function() {
        this.initProSwiper()
        setTimeout(() => {
          this.initConSwiper()
        }, 600)
      });
    },
    initProSwiper(){
      var _this = this
      _this.proSwiper = new Swiper(".swiper-container", {
        slidesPerView: '5',
        // grabCursor : true,
        slideToClickedSlide: true,
        roundLengths : true,
        centeredSlides: true,
        // allowTouchMove: false,
        // spaceBetween: 15,
        // slidesOffsetBefore: 5,
        loop: true,
        speed: 300,
        on: {
          slideChangeTransitionEnd: function(){
              this.slideToLoop(this.realIndex, 0, false);
          },
           
          // setTransition: function(swiper, transition) {
          // 	//console.log(transition);
          // 	for (var i = 0; i < swiper.slides.length; i++) {
          // 		var slide = swiper.slides.eq(i); 
          
          // 		slide.transition(transition);
          // 	}
          // }, 
          
          transitionStart: function transfor(e) {
            _this.changeProduct(e.realIndex)
          },
          slideChange: function transfor(e) {
            _this.realindex = e.realIndex;
          },
        },
      })
    },
    initConSwiper() {
      var _this = this
      _this.conSwiper = new Swiper('.swiper-container-content', {
        // slidesPerView: 'auto',
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
        },
      })
    },
    changeProduct(index) {
      this.product = this.productList[index]
    }  
    
  } 
  
}
</script>

<style lang="less" scoped>
  .second{
    width: 100%;
    // max-width: 480px;
    min-width: 320px;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all .3s;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .backbox{
			position: absolute;
			top: 0px;
			width: 100%;
			height: 100%;
			z-index: 0;
			overflow: hidden;
      // background-color: #fff;
			backdrop-filter: blur(10px) brightness(0.4);
		}
    .probox{
      position: relative;
      z-index: 1;
      width: 6.78rem;
      height: 10.78rem;
      margin: 0 auto;
      margin-top: 0.49rem;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 0.24rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .pro{  
        
        width: 6.54rem;
        height: 10.54rem;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 0.24rem;
        color: #fff;
        overflow: hidden;
        .videobox{
          width: 6.54rem;
          height: 6.54rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .video{
            width: 6.54rem;
          }
          .center-img{
            width: 100%;
          }
        }
        .titlebox{
          width: 5.76rem;
          height: 0.48rem;
          overflow: hidden;
          margin:0 auto;
          margin-top: 0.12rem;
          margin-bottom: 0.12rem;
          display: flex;
          justify-content: space-between;
          span{
            font-size: 0.24rem;
            color: #F3B919;
            line-height: 0.48rem;
          }
          .title{
            color: white;
            white-space: nowrap;
            text-overflow:ellipsis;
            display: inline-block;
            width: 4rem;
            overflow: hidden;
            height: 0.48rem;
            font-size: 0.36rem;
            font-weight: bold;
          }
          .pricebox{
            opacity: 0.85;
            .price{
              font-size: 0.36rem;;
            }
          }
        }
        .desc{
          width: 5.76rem;
          height: 0.38rem;
          margin:0 auto;
          opacity: 0.75;
          margin-bottom: 0.24rem;
          font-size: 0.28rem;
          white-space: nowrap;
          text-overflow:ellipsis;
          overflow: hidden;
        }
        .swiper-container-content{
          opacity: 0.5;
          margin: 0 auto;
          // width: 288px;
          width: 5.76rem;
          // height: 92px;
          height: 1.84rem;
          font-size: 0.24rem;
          overflow: hidden;
          .swiper-wrapper {
            // width: 5.76rem;
            // height: 1.84rem;
            .swiper-slide{
              // display: flex;
              // width: auto !important;
              // width: 100%;
              // height: 1.84rem;
              .itembox{
                overflow: auto;
                width: 5.76rem;
                height: 1.84rem;
                .xiangqing{
                  margin-bottom: 0.24rem;
                  .limit-num{
                    font-size: 0.36rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    .swiper-container{
      width: 100%;
      max-width: 480px;
      height: 2.54rem;
      margin: 0 auto;
      margin-top: 0.16rem;
      position: relative;
      display: flex;
      align-items: center;
      .swiper-wrapper{
        width: 100%;
        margin-top: 0.8rem;
        height: 2.06rem;
        .swiper-slide{
          // margin-right: 30px;
          cursor: pointer;
          width: 1.5rem !important;
          // height: 2.06rem;
          height: 1.5rem;
          transition: 300ms;
	        transform: scale(0.8);
          border-radius: 0.1rem;
          overflow: hidden;
          .proimg{
            width: 1.5rem;
            // height: 2.06rem;
            height: 1.5rem;
          }
        }
        .swiper-slide-active,.swiper-slide-duplicate-active{
          cursor: auto;
          transform: scale(1);
        }
      }
      .jiantou{
        width: 0.52rem;
        height: 0.52rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);  
        background: url(~assets/images/img_JianTou.png) no-repeat;
        background-size: cover;
        top: 0px;
      }
    }
  }
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/four_back.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".four[data-v-a41f2d76]{min-width:1200px;min-height:800px;width:100%;height:100vh;position:relative}.four .four_back[data-v-a41f2d76]{position:absolute;width:100%;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:cover}.four .four-box[data-v-a41f2d76]{width:100%;text-align:center;overflow:hidden;position:relative;z-index:1}.four .four-box .title[data-v-a41f2d76]{font-size:.75rem;color:#000;font-weight:700;margin:0 auto;margin-top:1.4125rem}.four .four-box .desc[data-v-a41f2d76]{font-size:.3rem;color:grey;margin:0 auto;margin-top:.7rem}.four .four-box .item-box[data-v-a41f2d76]{width:13.5375rem;margin:0 auto;margin-top:2.15rem;margin-bottom:.15rem;display:flex;flex-wrap:wrap}.four .four-box .item-box .item[data-v-a41f2d76]{width:2.25rem;height:1.25rem;margin-right:1.5125rem;margin-bottom:1.5rem;flex:2.5}.four .four-box .item-box .item .item-img[data-v-a41f2d76]{width:2.25rem}.four .four-box .item-box .item .item-title[data-v-a41f2d76]{font-size:.225rem;color:#000}.four .four-box .item[data-v-a41f2d76]:nth-child(4n){margin-right:0}", ""]);
// Exports
module.exports = exports;

<template>
  <div class="second">
    <!-- <div class="backbox"></div> -->
    <div class="pro-box" v-if="product">
      <div class="left-box" :class="[{slide_left: play}, {slide_left1: restart}, {slide_toleft: toplay}, {slide_toleft1: torestart}]">
        <!-- <div class="title"><span>{{getText(product, 'title')}}</span></div>
        <p class="desc">{{getText(product, 'desc')}}</p> -->
        <img class="left" src="~assets/images/line_Xian.png" alt="">
        <div class="jianjie">{{$t('second.role_profile')}}</div>
        <div id="content" class="content" v-html="getText(product, 'content')">{{getText(product, 'content')}}</div>
        <!-- <div class="like-box">
          <span class="like-item">
            <span v-if="product['like_' + product.id] === 0" class="icon notlike" @click="update(product.id, 'like_num', 1)"></span>
            <span v-else class="icon like"  @click="update(product.id, 'like_num', 0)"></span>
            {{product.like_num}}
          </span>
          <span class="like-item">
            <span v-if="product['dianzan_' + product.id] === 0" class="icon notdianzan"  @click="update(product.id, 'dianzan_num', 1)"></span>
            <span v-else class="icon dianzan"  @click="update(product.id, 'dianzan_num', 0)"></span>
            {{product.dianzan_num}}
          </span>
        </div> -->
      </div>
      <div class="center-box" :class="[{slide_top: play}, {slide_top1: restart}, {slide_totop: toplay}, {slide_totop1: torestart}]">
        <!-- <video class="video" :src="product.video" muted autoplay loop></video> -->
        <div class="title">{{getText(product, 'title')}}</div>
        <div class="desc">{{getText(product, 'desc')}}</div>
        <img class="center-img" :src="product.image" alt="">
      </div>
      <div class="right-box" :class="[{slide_right: play}, {slide_right1: restart}, {slide_toright: toplay}, {slide_toright1: torestart}]">
        <div class="xiangqing-title">{{$t('second.product_detail')}}</div>
        <a v-if="product.file" class="zuzhuang" :href="product.file" target="__blank">{{$t('second.assemble')}}</a>
        <p class="xiangqing">{{$t('second.code')}}： {{product.code}}</p>
        <p class="xiangqing caizhi">
          {{$t('second.material')}}： {{getText(product, 'material')}} <br />
          {{$t('second.scale')}}： {{product.scale}} <br />
          {{$t('second.dimensions')}}： {{product.specification}} <br />
          {{$t('second.weight')}}： {{product.weight}} <br />
          {{$t('second.box')}}： {{product.box_specification}} <br />
        </p>
        <i18n path="second.limit" tag="p" class="xiangqing">
          <!-- <span class="limit-num" place="num">{{product.limit_num}}</span> -->
          <span class="limit-num" place="num">TBD</span>
        </i18n>
        <!-- <p class="p-price">¥ <span class="price">{{product.price}}</span></p> -->
        <p class="p-price">¥ <span class="price">TBD</span></p>
        <img class="right" src="~assets/images/line_Xian.png" alt="">
        <div class="buy">{{$t('second.buy')}}</div>
      </div>
    </div>
    <div class="swiper_box">
      <!-- <div class="swiper-container" :class="[{slide_to_top: swiperToTop}, {slide_to_bottom: swiperToBottom}]" :style="{ width: swiperWidth + 'px' }"> -->
      <div class="swiper-container" :class="[{slide_to_top: swiperToTop}, {slide_to_bottom: swiperToBottom}]">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in productList" :key="index">
            <img class="proimg" :src="item.cover" alt="">
            <div class="mengban">{{getText(item, 'title')}}</div>
          </div>
        </div>
        <!-- <div class="img"></div> -->
        <!-- <div class="xia jiantou"></div> -->
        <!-- <div class="shang jiantou"></div> -->
      </div>
      <div class="xia jiantou"></div>
    </div>
  </div>
</template>

<script>
// 6.5.0版本
import Swiper from "swiper/swiper-bundle.min.js"
import "swiper/swiper-bundle.min.css"

export default {
  name: "Second",
  props: ["play", "restart", "toplay", "torestart", "swiperToTop", "swiperToBottom"],
  data() {
    return {
      productList: [],
      product: {},
      like: 0,
      dianzan: 0,
      proSwiper: {},
      realIndex: 0,
      swiperWidth: 950
    }
  },
  created () {
    this.getProduct()
  },
  methods: {
    async getProduct () {
      const { data: res } = await this.$http.get("v1/product/all")
			if (res.code !== 0) return this.$message.error(res.message)
      this.productList = res.data
      this.productList.forEach((item, index) => {
        item['like_'+ item.id] = localStorage.getItem('like_'+ item.id) == 1 ? localStorage.getItem('like_'+ item.id) : 0
        item['dianzan_'+ item.id] = localStorage.getItem('dianzan_'+ item.id) == 1 ? localStorage.getItem('dianzan_'+ item.id) : 0
      });
      this.product = this.productList[this.realIndex]
      const length = this.productList.length
      if (length <= 3) {
        this.swiperWidth = 460
      } else if (length <= 5) {
        this.swiperWidth = 780
      }
      this.$nextTick(function() {
        this.initProSwiper();
      });
    },
    initProSwiper(){
      
      // $(function(){
        var _this = this
      _this.proSwiper = new Swiper(".swiper-container", {
        slidesPerView: 'auto',
        // grabCursor : true,
        slideToClickedSlide: true,
        roundLengths : true,
        centeredSlides: true,
        allowTouchMove: false,
        // spaceBetween: 15,
        // slidesOffsetBefore: 50,
        loop: true,
        speed: 300,
        on: {
          slideChangeTransitionEnd: function(){
              this.slideToLoop(this.realIndex, 0, false);
          },
          // setTransition: function(swiper, transition) {
          // 	//console.log(transition);
          // 	for (var i = 0; i < swiper.slides.length; i++) {
          // 		var slide = swiper.slides.eq(i);
          // 		slide.transition(transition);
          // 	}
          // },
          transitionStart: function transfor(e) {
            if (_this.realIndex !== e.realIndex) {
              _this.changeProduct(e.realIndex)
              _this.realIndex = e.realIndex
            }
          },
          resize: function() {
            setTimeout(() => {
              this.update();
            }, 400);
          },
        },
      })
      // })
      
    },
    changeProduct(index) {
      this.$emit("change")
      setTimeout(() => {
        this.product = this.productList[index]
      }, 300)
    },
    async update(id, key, act) {
      const { data: res } = await this.$http.get(`v1/product/update?id=${id}&key=${key}&act=${act}`)
			if (res.code !== 17) {
        alert(res.message)
        return
      }
      this.product[key] = act === 1 ? this.product[key] + 1 : this.product[key] - 1
      if (key === 'like_num') {
        this.product['like_' + id ] = act
        localStorage.setItem('like_' + id, act)
      } else if (key === 'dianzan_num') {
        this.product['dianzan_' + id ] = act
        localStorage.setItem('dianzan_' + id, act)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @-webkit-keyframes left{
    from {-webkit-transform: translateX(-300px);opacity: 0;}
    to {-webkit-transform: translateX(0);opacity: 1;}
  }
  @-webkit-keyframes left1{
    from {-webkit-transform: translateX(-300px);opacity: 0;}
    to {-webkit-transform: translateX(0);opacity: 1;}
  }
  .slide_left{
    animation: left .5s ease-in .5s normal forwards;
    -moz-animation: left .5s ease-in .5s normal forwards;
    -webkit-animation: left .5s ease-in .5s normal forwards;
    -o-animation: left .5s ease-in .5s normal forwards;
  }
  .slide_left1{
    animation: left1 .5s ease-in .5s normal forwards;
    -moz-animation: left1 .5s ease-in .5s normal forwards;
    -webkit-animation: left1 .5s ease-in .5s normal forwards;
    -o-animation: left1 .5s ease-in .5s normal forwards;
  }
  @-webkit-keyframes toleft{
    from {-webkit-transform: translateX(0);opacity: 1;}
    to {-webkit-transform: translateX(-300px);opacity: 0;}
  }
  @-webkit-keyframes toleft1{
    from {-webkit-transform: translateX(0);opacity: 1;}
    to {-webkit-transform: translateX(-300px);opacity: 0;}
  }
  .slide_toleft{
    animation: toleft .5s ease-in normal;
    -moz-animation: toleft .5s ease-in normal;
    -webkit-animation: toleft .5s ease-in normal;
    -o-animation: toleft .5s ease-in normal;
  }
  .slide_toleft1{
    animation: toleft1 .5s ease-in normal;
    -moz-animation: toleft1 .5s ease-in normal;
    -webkit-animation: toleft1 .5s ease-in normal;
    -o-animation: toleft1 .5s ease-in normal;
  }
  @-webkit-keyframes top{
    from {-webkit-transform: translateY(-300px);opacity: 0;}
    to {-webkit-transform: translateY(0);opacity: 1;}
  }
  @-webkit-keyframes top1{
    from {-webkit-transform: translateY(-300px);opacity: 0;}
    to {-webkit-transform: translateY(0);opacity: 1;}
  }
  .slide_top{
    animation: top .5s ease-in normal forwards;
    -moz-animation: top .5s ease-in normal forwards;
    -webkit-animation: top .5s ease-in normal forwards;
    -o-animation: top .5s ease-in normal forwards;
  }
  .slide_top1{
    animation: top1 .5s ease-in normal forwards;
    -moz-animation: top1 .5s ease-in normal forwards;
    -webkit-animation: top1 .5s ease-in normal forwards;
    -o-animation: top1 .5s ease-in normal forwards;
  }
  @-webkit-keyframes totop{
    from {-webkit-transform: translateY(0);opacity: 1;}
    to {-webkit-transform: translateY(-300px);opacity: 0;}
  }
  @-webkit-keyframes totop1{
    from {-webkit-transform: translateY(0);opacity: 1;}
    to {-webkit-transform: translateY(-300px);opacity: 0;}
  }
  .slide_totop{
    animation: totop .5s cubic-bezier(.66,.05,1,.31) normal;
    -moz-animation: totop .5s cubic-bezier(.66,.05,1,.31) normal;
    -webkit-animation: totop .5s cubic-bezier(.66,.05,1,.31) normal;
    -o-animation: totop .5s cubic-bezier(.66,.05,1,.31) normal;
  }
  .slide_totop1{
    animation: totop1 .5s cubic-bezier(.66,.05,1,.31) normal;
    -moz-animation: totop1 .5s cubic-bezier(.66,.05,1,.31) normal;
    -webkit-animation: totop1 .5s cubic-bezier(.66,.05,1,.31) normal;
    -o-animation: totop1 .5s cubic-bezier(.66,.05,1,.31) normal;
  }
  @-webkit-keyframes right{
    from {-webkit-transform: translateX(300px);opacity: 0;}
    to {-webkit-transform: translateX(0);opacity: 1;}
  }
  @-webkit-keyframes right1{
    from {-webkit-transform: translateX(300px);opacity: 0;}
    to {-webkit-transform: translateX(0);opacity: 1;}
  }
  .slide_right{
    animation: right .5s ease-in .5s normal forwards;
    -moz-animation: right .5s ease-in .5s normal forwards;
    -webkit-animation: right .5s ease-in .5s normal forwards;
    -o-animation: right .5s ease-in .5s normal forwards;
  }
  .slide_right1{
    animation: right1 .5s ease-in .5s normal forwards;
    -moz-animation: right1 .5s ease-in .5s normal forwards;
    -webkit-animation: right1 .5s ease-in .5s normal forwards;
    -o-animation: right1 .5s ease-in .5s normal forwards;
  }
  @-webkit-keyframes toright{
    from {-webkit-transform: translateX(0);opacity: 1;}
    to {-webkit-transform: translateX(300px);opacity: 0;}
  }
  @-webkit-keyframes toright1{
    from {-webkit-transform: translateX(0);opacity: 1;}
    to {-webkit-transform: translateX(300px);opacity: 0;}
  }
  .slide_toright{
    animation: toright .5s ease-in normal;
    -moz-animation: toright .5s ease-in normal;
    -webkit-animation: toright .5s ease-in normal;
    -o-animation: toright .5s ease-in normal;
  }
  .slide_toright1{
    animation: toright1 .5s ease-in normal;
    -moz-animation: toright1 .5s ease-in normal;
    -webkit-animation: toright1 .5s ease-in normal;
    -o-animation: toright1 .5s ease-in normal;
  }
  @-webkit-keyframes slidetoTop{
    from {-webkit-transform: translateY(100px);opacity: 0;}
    to {-webkit-transform: translateY(0);opacity: 1;}
  }
  .slide_to_top{
    animation: slidetoTop .5s ease-in normal forwards;
    -moz-animation: slidetoTop .5s ease-in normal forwards;
    -webkit-animation: slidetoTop .5s ease-in normal forwards;
    -o-animation: slidetoTop .5s ease-in normal forwards;
  }
  @-webkit-keyframes slidetobottom{
    from {-webkit-transform: translateY(0);opacity: 1;}
    to {-webkit-transform: translateY(-100px);opacity: 0;}
  }
  .slide_to_bottom{
    animation: slidetobottom .5s ease-in normal forwards;
    -moz-animation: slidetobottom .5s ease-in normal forwards;
    -webkit-animation: slidetobottom .5s ease-in normal forwards;
    -o-animation: slidetobottom .5s ease-in normal forwards;
  }
  .second{
    width: 100%;
    min-width: 1200px;
    min-height: 800px;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: url(~assets/images/bg_BeiJing.jpg) no-repeat center center;
    background-size: cover;
    font-family: PingFangSC-Regular, sans-serif;
    // background-position: center -130px;
    // transition: all .3s;
    .backbox{
			position: absolute;
			top: 0px;
			width: 100%;
			height: 100%;
			z-index: 0;
			overflow: hidden;
			backdrop-filter: blur(10px);
		}
    .pro-box{
      width: 22.5rem;
      // height: 495px;
      margin: 0 auto;
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      color:white;
      p{
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
        margin: 0;
      }
      .left-box{
        padding-left: 0.625rem;
        margin-top: 1.125rem;
        width: 5rem;
        height: 5.3125rem;
        position: relative;
        opacity: 0;
        z-index: 2;
        // .title{
        //   margin-left: -25px;
        //   width: 417px;
        //   height: 82px;
        //   background: url(~assets/images/img_BiaoTi.png) no-repeat;
        //   background-size: cover;
        //   line-height: 82px;
        //   font-size: 36px;
        //   font-weight: bold;
        //   color: #ffffff;
        //   margin-bottom: 16px;
        //   padding-left: 25px;
        //   span{
        //     display: block;
        //     width: 380px;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        //   }
        // }
        // .desc{
        //   opacity: 0.6;
        //   font-size: 24px;
        //   margin-bottom: 65px;
        // }
        .left{
          width: 2.1125rem;
          position: absolute;
          top: 0.25rem;
          right: -2rem;
        }
        .jianjie{
          width: 2.25rem;
          height: 0.4125rem;
          background: url(~assets/images/img_BiaoTiXiao.png) no-repeat;
          background-size: cover;
          color: #ffffff;
          text-align: center;
          font-size: 0.3rem;
          margin-bottom: 0.125rem;
          line-height: 0.4125rem;
        }
        .content{
          opacity: 0.8;
          color: #000000;
          width: 4.625rem;
          padding-right: 0.125rem;
          max-height: 5rem;
          overflow: auto;
          font-size: 0.225rem;
          line-height: 0.425rem;
          scrollbar-color: transparent transparent;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 13;
          // -webkit-box-orient: vertical;
        }
        .content::-webkit-scrollbar {
          width: 0.025rem;
          background-color: #000;
        }
        .content::-webkit-scrollbar-thumb {
          width: 0.05rem;
          // background: url(~assets/images/bar_XiaLa.png) no-repeat center center;
          background-color:#E47AFF;
          box-shadow: 0 0 4px #E47AFF;
        }
        .like-box{
          margin-top: 0.25rem;
          float: left;
          width: 2.225rem;
          height: 0.3rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .like-item{
            display: flex;
            align-items: center;
            font-size: 0.225rem;
          }
          .icon{
            display: inline-block;
            width: 0.275rem;
            height: 0.3375rem;
            margin-right: 0.2rem;
          }
          .icon:hover{
            cursor: pointer;
          }
          .notlike{
            background: url(~assets/images/not_like.png) no-repeat;
            background-size: cover;
          }
          .notlike:hover, .like{
            background: url(~assets/images/like.png) no-repeat;
            background-size: cover;
          }
          .notdianzan{
            background: url(~assets/images/not_zan.png) no-repeat;
            background-size: cover;
          }
          .notdianzan:hover, .dianzan{
            background: url(~assets/images/zan.png) no-repeat;
            background-size: cover;
          }
        }
      }
      .center-box{
        width: 6.25rem;
        // margin: 0 auto;
        margin-top: -1.25rem;
        // height: 500px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1;
        .title{
          width: 100%;
          height: 1.025rem;
          text-align: center;
          line-height: 1.025rem;
          font-size: 0.75rem;
          font-weight: bold;
          color: #000000;
          margin-bottom: 0.2rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .desc{
          width: 100%;
          text-align: center;
          color: #000000;
          opacity: 0.6;
          font-size: 0.3rem;
          margin-bottom: 0.25rem;
        }
        .center-img{
          width: 100%;
          -webkit-box-reflect: below -6px -webkit-linear-gradient(transparent, transparent 60%, rgba(255,255,255,0.2));;
        }
        .video{
          width: 100%;
        }
      }
      .right-box{
        margin-top: 1.125rem;
        z-index: 2;
        width: 5rem;
        position: relative;
        opacity: 0;
        .xiangqing-title{
          margin-left: -0.5rem;
          width: 2.25rem;
          height: 0.4125rem;
          background: url(~assets/images/img_BiaoTiXiao.png) no-repeat;
          background-size: cover;
          color: #fff;
          text-align: center;
          font-size: 0.3rem;
          line-height: 0.4125rem;
        }
        .zuzhuang{
          display: flex;
          justify-content: center;
          align-items: center;
          background: #EAB98B;
          border-radius: 0.25rem;
          width: 1.75rem;
          height: 0.5rem;
          color: #fff;
          font-size: 0.2rem;
          margin-top: 0.125rem;
          margin-left: -0.5rem;
        }
        .zuzhuang:hover{
          text-decoration: none;
          color: #76380F;
        }
        .xiangqing{
          font-weight: bold;
          margin-left: -0.5rem;
          font-size: 0.25rem;
          margin-top: 0.125rem;
          color: #000;
        }
        .caizhi{
          margin-top: 0;
        }
        .right{
          width: 2.1125rem;
          position: absolute;
          top: 3.75rem;
          left: -2.5rem;
          transform: rotateY(180deg);
        }
        .limit-num{
          font-size: 0.5rem;
        }
        .p-price{
          margin-left: -0.5rem;
          margin-top: 0px;
          // margin-bottom: 33px;
          font-size: 1rem;
          font-weight: bolder;
          color: #000524;
          // text-shadow: 3px 3px #ff00f4, -2px 0 #08D518;
          text-shadow: 3px 3px #7ff4ff;
          .price{
            font-size: 1rem;
          }
        }
        .buy{
          margin-left: -0.5rem;
          width: 4.025rem;
          height: 1.1rem;
          border-radius: 0.0625rem;
          background: url(~assets/images/btn_GouMai.png) no-repeat;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.525rem;
          color: #512f11;
          font-weight: bolder;
          // text-shadow: 0 1px #fff;
        }
        .buy:hover{
          cursor: pointer;
        }
      }
    }
    .swiper_box{
      width: 9.75rem;
      z-index: 10;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0.25rem;
      .jiantou{
        width: 0.45rem;
        height:0.4rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      .xia{
        background: url(~assets/images/img_JianTou.png) no-repeat;
        background-size: cover;
        top: -0.25rem;
      }
    }
    .swiper-container{
      width: 9.75rem;
      // height: 200px;
      margin: 0 auto;
      // margin-top: -20px;
      
      .swiper-wrapper{
        display: flex;
        align-items: center;
        margin-top: 0.25rem;
        .swiper-slide{
          // margin-right: 30px;
          cursor: pointer;
          width: 1.95rem !important;
          height: 1.95rem;
          transition: 300ms;
	        transform: scale(0.8);
          // border-radius: 5px;
          overflow: hidden;
          .proimg{
            transition: .5s;
            width: 1.95rem;
            height: 1.95rem;
            cursor: pointer;
          }
          .mengban{
            width: 1.95rem;
            height: 1.95rem;
            position: absolute;
            top: 0px;
            left: 0px;
            cursor: pointer;
            background: rgba(0, 0, 0, 0.3);
            opacity: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 0.225rem;
            transition: all .5s;
          }
          .mengban:hover{
            opacity: 1;
          }
          .proimg:hover{
            transform: scale(1.1);
          }
        }
        .swiper-slide-active,.swiper-slide-duplicate-active{
          cursor: auto;
          transform: scale(1);
        }
      }
      .img{
        width: 1.95rem;
        height: 0.7375rem;
        background: url(~assets/images/img_XuanZhong.png) no-repeat center center;
        background-size: cover;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0.125rem;
      }
      .jiantou{
        width: 0.45rem;
        height:0.4rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -0.125rem;
      }
      .xia{
        background: url(~assets/images/img_JianTou.png) no-repeat;
        background-size: cover;
        top: 0px;
      }
      .shang{
        background: url(~assets/images/xia.png) no-repeat;
        background-size: cover;
        bottom: 0px;
        transform: translateX(-50%) rotateX(180deg);
      }
    }
  }
</style>

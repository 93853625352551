<template>
  <div>
    <div class="content">
      <div class="title">用户隐私政策</div>
      <p>我们秉承“尊重并保护所有使用服务用户的个人隐私权”。为了给您提供更准确的个性化服务，我们会按照本隐私权政策的规定使用您的个人信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本网站不会将这些信息对外披露或向第三方提供。</p>
      <p>本隐私权政策将会进行不定时更新。 您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。</p>
      <h2>1. 适用范围</h2>
      <p>(1)	在您注册本网站帐号时，您根据本网站要求提供的个人注册信息；</p>
      <p>(2)	在您使用本网站的服务，或访问本网站时，本网站自动接收并记录的您的浏览器和PC上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；</p>
      <p>(3)	本网站通过合法途径从商业伙伴处取得的用户个人数据。</p>
      <p>您了解并同意，以下信息不适用本隐私权政策：</p>
      <p>(1)	您在使用本平台提供的搜索服务时输入的关键字信息；</p>
      <p>(2)	本网站收集到的您在本网站发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；</p>
      <p>(4)	违反法律规定或违反本应用规则行为及本应用已对您采取的措施。</p>
      <p>(5)	本隐私政策中涉及的个人信息及个人敏感信息主要包括：</p>
      <p>(6)	基本信息：姓名、电话号码；</p>
      <p>(7)	网站使用数据（含软件浏览及点击记录、软件使用记录、通过麦克风输入的内容）；</p>
      <p>(8)	个人常用设备信息（含硬件型号、操作系统类型）；</p>
      <p>(9)	个人位置信息（含精准定位信息）；</p>
      <p>(10)	写入并读取设备的外置储存器；</p>
      <p>(11)	接受并读取短信；</p>
      <p>(12)	设备的拍摄功能；</p>
      <h2>2. 信息使用</h2>
      <p>(1)	本网站不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本网站（含本网站关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有相关资料。</p>
      <p>(2)	本网站亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。本网站的用户如从事上述活动，一经发现，本网站有权立即终止与该用户的服务协议。</p>
      <h2>3. 信息披露</h2>
      <p>在如下情况下，本网站将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</p>
      <p>(1)	经您事先同意，向第三方披露；</p>
      <p>(2)	为提供您所要求的产品和服务，而必须与第三方分享您的个人信息时；</p>
      <p>(3)	根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
      <p>(4)	如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</p>
      <p>(5)	在本网站创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本网站有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。</p>
      <p>(6)	其它本应用根据法律、法规或者网站政策认为合适的披露。</p>
      <h2>4. 信息存储和交换</h2>
      <p>本网站收集的有关您的信息和资料将保存在本网站及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本网站收集信息和资料所在地的境外并在境外被访问、存储和展示。</p>
      <h2>5. Cookie的使用</h2>
      <p>(1)	在您未拒绝接受cookies的情况下，本网站会在您的计算机上设定或取用cookies ，以便您能登录或使用依赖于cookies的本网站平台服务或功能。本网站使用cookies可为您提供更加周到的个性化服务，包括推广服务。</p>
      <p>(2)	您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本网站的网络服务或功能。</p>
      <p>(3)	通过本网站所设cookies所取得的有关信息，将适用本政策。</p>
      <h2>6. 信息安全</h2>
      <p>本网站均有安全保护功能，请妥善保管您的用户名及密码等信息。本网站将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。</p>
      <h2>7. 本隐私政策的更改</h2>
      <p>(1)	如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。</p>
      <p>(2)	本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。</p>
      <p>为防止向第三方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是用户名及密码发生泄露，请您立即联络本公司客服，以便采取相应措施。</p>
      <p>联系电话：0571-88570736</p>
      <p>联系邮箱：clare.x@x1art.com</p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/footer.vue"

export default {
  components: {
    Footer,
  },
  created(){
    if (this._isMobile()) {
      this.$router.replace({ name: 'M_policy' })
    } 
  },
  methods: {
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)      
      return flag;
    },
  }
}
</script>

<style lang="less" scoped>
.content{
  margin: 0 auto;
  max-width:1280px; 
  color: white;
  .title {
    text-align: center;
    margin: 30px auto;
    font-size: 30px;
  }
  p{
    font-size: 16px;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 20px;
    margin: 20px 0;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "views/Home.vue"
import MobileHome from "views/M_home.vue"
import Policy from "views/Policy.vue"
import Service from "views/Service.vue"
import M_policy from "views/M_policy.vue"
import M_service from "views/M_service.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta:{
      title: 'X1ART - XQ Collectible Figures'
    }
  },
  {
    path: "/mobile",
    name: "MobileHome",
    component: MobileHome,
    meta:{
      title: 'X1ART - XQ Collectible Figures'
    }
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
    meta:{
      title: 'X1ART - XQ Collectible Figures'
    }
  },
  {
    path: "/service",
    name: "Service",
    component: Service,
    meta:{
      title: 'X1ART - XQ Collectible Figures'
    }
  },
  {
    path: "/m_policy",
    name: "M_policy",
    component: M_policy,
    meta:{
      title: 'X1ART - XQ Collectible Figures'
    }
  },
  {
    path: "/m_service",
    name: "M_service",
    component: M_service,
    meta:{
      title: 'X1ART - XQ Collectible Figures'
    }
  },
];

const scrollBehavior = function (to, from, savedPosition) {
  if (to.hash) {
    return {
      // 通过 to.hash 的值來找到对应的元素
      selector: to.hash
    }
  }
}
const router = new VueRouter({
  mode: 'history', 
  routes,
  scrollBehavior
})

export default router

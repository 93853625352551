<template>
  <!-- <router-view /> -->
  <div class="disable">
    <!-- <img src="~assets/images/disable.png" alt=""> -->
    <!-- <p>Our website is under construction, but we are ready to got special surprise for our subscribers only</p> -->
  </div>
</template>
<script>
export default {
  name: 'App',
  // created() {
  //   if (this._isMobile()) {
  //     import('assets/js/phone_flexable.js')
  //   } else {
  //     import('assets/js/flexable.js')
  //   }
  // },
  // methods:{
	// 	_isMobile(){
	// 		let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)      
	//     return flag;
	// 	}
	// }
}
</script>
<style lang="less">
body{
  // background-color: black !important;
}
.disable {
  background: url(~assets/images/disable1.jpg) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  // font-size: 30px;
  // margin: 0 auto;
  // margin-top: 10%;
  // text-align: center;
  img {
    // width: 500px;
    width: 100%;
    height: 100%;
  }
  p {
    margin-top: 10px;
    text-align: center;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

module.exports = {
  home: {
    index: 'Index',
    product: 'Product Center',
    product_safety_guarantee: 'Product safety guarantee',
    ip: 'IP authorization',
  },
  second: {
    role_profile: 'role profile',
    product_detail: 'product details',
    material: 'material',
    limit: 'limited {num}',
    buy: 'Buy it now',
    code: 'Item Code',
    scale: 'Scale',
    dimensions: 'Dimensions',
    weight: 'Weight',
    box: 'Box A',
    assemble: 'Assembly instructions',
  },
  four: {
    ip: 'IP AUTHORIZATION',
    partner: 'We have good cooperative relations with major IP companies. In the future, we will bring you more IP roles!',
  },
  footer: {
    privacy_policy: 'privacy policy',
    term_of_service: 'term of service',
    contact: 'contact us',
  }
 }
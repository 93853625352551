<template>
  <div class="first">
    <video class="myVideo" loop muted data-autoplay>
        <source src="~assets/1.mp4" type="video/mp4">
    </video>
    <div class="back">
      <lottie :options="defaultOptions" v-on:animCreated="handleAnimation"/>
    </div>
    <div class="header">
      <img class="logo" src="~assets/images/logo.png" alt="">
      <div class="header-right">
        <a class="weibo butn" href="https://weibo.com/x1art/" target="__blank"></a>
        <div class="weixin butn"></div>
        <div :class="['lan', this.$store.state.lang === 'zh' ? 'lan-btn' : '']" @click="handleCommand('zh')">CN</div>
        <div :class="['lan', this.$store.state.lang === 'en' ? 'lan-btn' : '']" @click="handleCommand('en')">EN</div>
      </div>
    </div>
    
  </div>
</template>

<script>
import * as animationData from "assets/json/home.json"

export default {
  name: "First",
  data() {
    return {
      defaultOptions: { 
        animationData: animationData.default
      },
      anim: {}
    }
  },
  created() {
    animationData.assets.forEach((item, index) => {
      item.u = ''
      item.p = item.p ? require(`assets/images/home_lottie/img_${index}.png`) : ''
    })
  },
  methods: {
    handleCommand(command) {
      this.$store.commit('change', command)
    },
    handleAnimation: function(anim) {
        this.anim = anim;
    },
  }
}
</script>

<style lang="less" scoped>
.first {
  width: 100%;
  min-width: 1200px;
  min-height: 800px;
  height: calc(100vh);
  position: relative;
  overflow: hidden;
  .myVideo {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height:100%;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
  }
  .back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(~assets/images/first_back.png) no-repeat;
    background-size: cover;
    background-color: rgba(0,0,0,.85);
  }
  .header {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    .logo{
      width: 2rem;
      margin-left: 0.5rem;
      margin-top: 0.2rem;
    }
    .header-right{
      display: flex;
      justify-content: space-between;
      margin-top: 0.3rem;
      margin-right: 1rem;
      width: 5.6625rem;
      .butn {
        width: 1.7rem;
        height: 0.7rem;
      }
      .butn:hover {
        cursor: pointer;
      }
      .weibo {
        background: url(~assets/images/weibo.png) no-repeat;
        background-size: contain;
      }
      .weibo:hover{
        background: url(~assets/images/weibo1.png) no-repeat;
        background-size: contain;
      }
      .weixin {
        background: url(~assets/images/weixin.png) no-repeat;
        background-size: contain;
      }
      .weixin:hover{
        background: url(~assets/images/weixin1.png) no-repeat;
        background-size: contain;
      }
      .lan{
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        border: 0.0125rem solid white;
        font-size: 0.25rem;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .lan-btn{
        cursor: pointer;
        background-color: white;
        color: black;
      }
      .lan:hover{
        cursor: pointer;
        background-color: white;
        color: black;
      }
    }
  }
}
</style>

<template>
  <div class="footer">
    <div class="footer-img">
      <a class="item-img bilibili" href="https://space.bilibili.com/106874236" target="__blank"></a>
      <a class="item-img douyin" href="https://www.douyin.com/" target="__blank"></a>
      <div class="item-img weixin"></div>
      <a class="item-img weibo" href="https://weibo.com/x1art/" target="__blank"></a>
    </div>
    <a href="https://beian.miit.gov.cn/" target="_blank" class="bei">浙ICP备20027847号-3</a>
  </div>
</template>

<script>
export default {
  name: "M_footer",
  data() {
    return {

    }
  },
}
</script>

<style lang="less" scoped>
  .footer{
    // max-width: 480px;
    min-width: 320px;
    width: 100%;
    height: 3.5rem;
    text-align: center;
    background: #000000;
    color: white;
    overflow: hidden;
    position: relative;
    z-index: 1;
    .footer-img{
      width: 4.64rem;
      height: 0.8rem;
      margin:0 auto;
      margin-top: 1.2rem;
      display: flex;
      justify-content: space-between;
      .item-img{
        width: 0.8rem;
        height: 0.8rem;
      }
      .bilibili{
        background: url(~assets/images/bilibili.png) no-repeat;
        background-size: cover;
      }
      .douyin{
        background: url(~assets/images/douyin.png) no-repeat;
        background-size: cover;
      }
      .weixin{
        background: url(~assets/images/wechat.png) no-repeat;
        background-size: cover;
      }
      .weibo{
        background: url(~assets/images/weibo_logo.png) no-repeat;
        background-size: cover;
      }
    }
    a {
      color: white;
      text-decoration: none;
    }
    .bei{
      display: block;
      font-size: 0.28rem;
      margin: 0 auto;
      margin-top: 0.48rem;
    }
  }
</style>

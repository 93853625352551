import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/swiper-bundle.min.css'
import '@/assets/css/fullpage.css'
import "@/assets/js/iscroll.js"
import axios from 'axios'
import  'fullpage.js/vendors/scrolloverflow'
import VueFullPage from '@/assets/js/vue-fullpage.js'
import lottie from 'vue-lottie'
import VueI18n from 'vue-i18n'

Vue.use(Vuex)
Vue.use(VueI18n)

const windowLang = window.navigator.language.toLowerCase() === 'zh-cn' ? 'zh' : window.navigator.language.toLowerCase()
/*---------使用语言包-----------*/
const i18n = new VueI18n({
  locale: sessionStorage.getItem('lang') || windowLang || 'zh',    // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh': require('./common/lang/zh'),   // 中文语言包
    'en': require('./common/lang/en')    // 英文语言包
  }
})

const store = new Vuex.Store({
  state: {
    lang: i18n.locale
  },
  mutations: {
    change (state, lang) {
      sessionStorage.setItem('lang', lang)
      i18n.locale = lang
      state.lang = lang
    }
  }
})

function _isMobile(){
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)      
  return flag;
}
if (_isMobile()) {
  import ('assets/js/phone_flexable.js')
} else {
  import ('assets/js/flexable.js')
}
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
Vue.prototype.$http = axios
Vue.use(VueFullPage)

Vue.component('lottie', lottie)

Vue.prototype.getText = function (item, key){
  return store.state.lang === 'zh' ? item[key] : item[key + '_en']
}

new Vue({
  router,
  i18n, 
  store,
  render: h => h(App)
}).$mount('#app')
 

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/bilibili.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/images/douyin.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/images/wechat.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/images/weibo_logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".footer[data-v-89c3c5c4]{min-width:1200px;width:100%;position:relative;z-index:1;height:3.3375rem;text-align:center;background:linear-gradient(180deg,#000 50%,#171923 0);color:#fff;overflow:hidden}.footer .footer-text[data-v-89c3c5c4]{display:flex;justify-content:space-between;width:5.1rem;height:.3rem;margin:0 auto;margin-top:.6374rem;font-size:.225rem}.footer .footer-text a[data-v-89c3c5c4]{color:#fff;text-decoration:none}.footer .footer-img[data-v-89c3c5c4]{width:2.9rem;height:.5rem;margin:0 auto;margin-top:.9625rem;display:flex;justify-content:space-between}.footer .footer-img .item-img[data-v-89c3c5c4]{width:.5rem;height:.5rem}.footer .footer-img .bilibili[data-v-89c3c5c4]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover}.footer .footer-img .douyin[data-v-89c3c5c4]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:cover}.footer .footer-img .weixin[data-v-89c3c5c4]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:cover}.footer .footer-img .weibo[data-v-89c3c5c4]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat;background-size:cover}.footer a[data-v-89c3c5c4]{color:#fff;text-decoration:none}.footer .bei[data-v-89c3c5c4]{display:block;font-size:.175rem;margin:0 auto;margin-top:.3rem}", ""]);
// Exports
module.exports = exports;

<template>
  <div class="four">
    <div class="four_back"></div>
    <div class="four-box">
      <!-- <img class="use" src="~assets/images/use.png" alt=""> -->
      <div class="title">{{$t('four.ip')}}</div>
      <!-- <div class="desc">{{$t('four.partner')}}</div> -->
      <div class="swiper-container-item">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in list" :key="index">
            <template v-for="(val, i) in item">
              <img class="item-img" :src="val.image" alt="">
            </template>
          </div>
        </div>
        <div class="swiper-pagination-item"></div>
      </div>
    </div>
  </div>
</template>

<script>
// 6.5.0版本
import Swiper from "swiper/swiper-bundle.min.js"
import "swiper/swiper-bundle.min.css"

export default {
  name: "M_four",
  data() {
    return {
      list: [],
      i: 0,
      arr: [],
      itemSwiper: {},
    }
  },
  created() {
    this.getBanner()
  },
  methods: {
    async getBanner () {
      const { data: res } = await this.$http.get("v1/banner/all")
			if (res.code !== 0) return this.$message.error(res.message)
      res.data.forEach((item, index)=> {
        if (index % 8 === 0) {
          this.i++
          if (this.arr.length > 0) {
            this.list.push(this.arr)
            this.arr = []
          }
        }
        if (index <= (this.i + 1) * 8) {
          this.arr.push(item)
        }
      });
      if (this.arr.length > 0) {
        this.list.push(this.arr)
      }
      this.$nextTick(function() {
        this.initItemSwiper()
      });
    },
    initItemSwiper() {
      var _this = this
      _this.itemSwiper = new Swiper('.swiper-container-item', {
        pagination: {
          el: '.swiper-pagination-item',
          bulletClass : 'item-bullet',
          bulletActiveClass: 'item-bullet-active',
        },
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .four{
    // max-width: 480px;
    min-width: 320px;
    width: 100%;
    padding-bottom: 1.2rem;
    // position: absolute;
    bottom: 3.5rem;
    .four_back{
      // top: -5px;
      position: absolute;
      width: 100%;
      height: 100% ;
      background: url(~assets/images/four_back.png) no-repeat;
      // background-size: contain;
    }
    .four-box{
      width: 100%;
      // height: 100%;
      text-align: center;
      overflow: hidden;
      position: relative;
      z-index: 1;
      .title{
        font-size: 0.48rem;
        color: black;
        font-weight: bold;
        margin: 0 auto;
        margin-top: 1.2rem;
        margin-bottom: 0.72rem;
      }
      .swiper-container-item{
        max-width: 480px;
        width: 7.4rem;
        margin: 0 auto;
        overflow: hidden;
        .swiper-wrapper{
          width: 100%;
          .swiper-slide{
            width: 3.6rem;
            margin-bottom: 0.24rem;
            .item-img{
              width: 3.6rem;
              height: 2rem;
            }
          }
        }
      }
    }
  }
</style>

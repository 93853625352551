<template>
  <div class="home_div">
    <!-- <video class="home_video" loop muted data-autoplay>
        <source src="~assets/home.mp4" type="video/mp4">
    </video> -->
    <navbar />
    <video class="home_video" src="~assets/home.mp4" muted autoplay loop></video>
    <full-page ref="fullpage" :options="options">
      <div class="section" id="section0">
        <First />
      </div>
      <div class="section" id="section1">
        <Second />
      </div>
      <div class="section" id="section2">
        <Third :play="thirdPlay" :toplay="thirdToplay"/>
      </div>
      <div class="section" id="section3">
        <Four />
        <Footer />
      </div>
    </full-page>
  </div>
</template>
 
<script>
// @ is an alias to /src
import First from "@/components/m_first.vue"
import Second from "@/components/m_second.vue"
import Third from "@/components/m_third.vue"
import Four from "@/components/m_four.vue"
import Footer from "@/components/M_footer.vue"
import navbar from "components/m_navbar.vue"

export default {
  name: "Home",
  data() {
    return {
      thirdPlay: false,
      thirdToplay: false,
      options: {
        anchors: ["page1", "page2", "page3", "page4"],
        css3: true,
        scrollOverflow:true,
        scrollOverflowReset: true,
        // scrollOverflowOptions: {
        //   scrollY: false
        // },
        // fitToSection: true,
        scrollingSpeed: 600,
        bigSectionsDestination: 'top',
        // scrollBar: true,
        // autoScrolling: false,
        normalScrollElements: '.itembox, .sticky',
        onLeave: this.onLeave,
        afterLoad: function(origin, destination, direction) {
          if (destination.index === 1) {
            $('video').each(function () {
                //playing the video
                $(this).get(0).play();
            });
          }
        },
      },
    };
  },
  // mounted() {
  //   new fullpage('#fullpage', {
  //     anchors: ["page1", "page2", "page3", "page4"],
  //     menu: '.menu',
  //     css3: true,
  //     scrollOverflow:true,
  //     // scrollOverflowOptions: {
  //     //   scrollY: false
  //     // },
  //     fitToSection: true,
  //     scrollingSpeed: 600,
  //     bigSectionsDestination: 'top',
  //     scrollOverflowReset: true,
  //     scrollOverflowResetKey: 'YWx2YXJvdHJpZ28uY29tXzlRaGMyTnliMnhzVDNabGNtWnNiM2RTWlhObGRBPT14Ykk='
  //   });
  // },
  components: {
    First,
    Second,
    Third,
    Four,
    Footer,
    navbar,
  },
  created(){
    if (!this._isMobile()) {
      this.$router.replace({ name: 'Home' })
    } 
  },
  methods: {
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)      
      return flag;
    },
    onLeave(origin, destination, direction) {
      if (destination.index === 2) {
        this.thirdPlay = true
        this.thirdToplay = false
      } else if (origin.index === 2) {
        this.thirdToplay = true
        this.thirdPlay = false
      }
    },
  }
};
</script>
<style lang="less" scoped>
  .fp-scroller{
    overflow:hidden;
  }
  .home_div {
    margin: 0 auto;
    position: relative;
    font-size: 0.28rem;
    background-color: white;
    .home_video{
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      width: 100%;
      background-position: center center;
      background-size: contain;
      object-fit: cover;
    }
    a {
      text-decoration: none;
    }
    #section0{
      margin-top: -0.98rem;
    }
  }
</style>

<template>
  <div class="home_div">
    <!-- <video class="home_video" loop muted data-autoplay>
        <source src="~assets/home.mp4" type="video/mp4">
    </video> -->
    <video class="home_video" src="~assets/home.mp4" muted autoplay loop></video>
    <full-page ref="fullpage" :options="options" @after-load="afterLoad()">
      <div class="section" id="section0">
        <First />
      </div>
      <div class="section" id="section1">
        <Second :play="play" :restart="restart" :toplay="toplay" :torestart="torestart" :swiperToTop="swiperToTop" :swiperToBottom="swiperToBottom" v-on:change="change" />
      </div>
      <div class="section" id="section2">
        <Third :play="thirdPlay" :toplay="thirdToplay"/>
      </div>
      <div class="section" id="section3">
        <Four />
        <Footer />
      </div>
    </full-page>
    <!-- <div id="fullpage">
      <div class="section" id="section0">
        <First />
      </div>
      <div class="section" id="section1">
        <Second />
      </div>
      <div class="section" id="section2">
        <Third />
      </div>
      <div class="section" id="section3">
        <Four />
        <Footer />
      </div>
    </div> -->
    <ul class="menu">
      <li data-menuanchor="page1" class="active"><a href="#page1">{{$t('home.index')}}</a></li>
      <li data-menuanchor="page2"><a href="#page2">{{$t('home.product')}}</a></li>
      <li data-menuanchor="page3"><a href="#page3">{{$t('home.product_safety_guarantee')}}</a></li>
      <li data-menuanchor="page4"><a href="#page4">{{$t('home.ip')}}</a></li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
import First from "@/components/first.vue"
import Second from "@/components/second.vue"
import Third from "@/components/third.vue"
import Four from "@/components/four.vue"
import Footer from "@/components/footer.vue"

export default {
  name: "Home",
  data() {
    return {
      play: false,
      restart: false,
      toplay: false,
      torestart: false,
      thirdPlay: false,
      thirdToplay: false,
      swiperToTop: false,
      swiperToBottom: false,
      options: {
        anchors: ["page1", "page2", "page3", "page4"],
        menu: '.menu',
        css3: true,
        scrollOverflow:true,
        scrollOverflowReset: true,
        // scrollOverflowOptions: {
        //   scrollY: false
        // },
        // fitToSection: true,
        scrollingSpeed: 900,
        bigSectionsDestination: 'top',
        // scrollBar: true,
        // autoScrolling: false,
        normalScrollElements: '#content',
        onLeave: this.onLeave
      },
    };
  },
  mounted() {
    // new fullpage('#fullpage', {
    //   anchors: ["page1", "page2", "page3", "page4"],
    //   menu: '.menu',
    //   css3: true,
    //   scrollOverflow:true,
    //   // scrollOverflowOptions: {
    //   //   scrollY: false
    //   // },
    //   fitToSection: true,
    //   scrollingSpeed: 600,
    //   bigSectionsDestination: 'top',
    //   scrollOverflowReset: true,
    //   scrollOverflowResetKey: 'YWx2YXJvdHJpZ28uY29tXzlRaGMyTnliMnhzVDNabGNtWnNiM2RTWlhObGRBPT14Ykk='
    // });
  },
  components: {
    First,
    Second,
    Third,
    Four,
    Footer,
  },
  created(){
    if (this._isMobile()) {
      this.$router.replace({ name: 'MobileHome' })
    } 
  },
  methods: {
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)      
      return flag;
    },
    onLeave(origin, destination, direction) {
      // console.log(origin)
      // console.log(destination)
      // console.log(destination)
      if (destination.index === 1) {
        this.$nextTick(function() {
          this.swiperToBottom = false
          this.swiperToTop = true

          this.play = true
          this.toplay = false
        })
        
        $('video').each(function () {
            $(this).get(0).play();
        });
      } else if (origin.index === 1) {
        this.$nextTick(function() {
          this.swiperToTop = false
          this.swiperToBottom = true
          this.toplay = true
          this.play = false
        })
      }

      if (destination.index === 2) {
        this.thirdPlay = true
        this.thirdToplay = false
      } else if (origin.index === 2) {
        this.thirdToplay = true
        this.thirdPlay = false
      }
    },
    change() {
      this.play = false
      this.restart = false
      this.toplay = !this.toplay
      this.torestart = !this.torestart
      setTimeout(() => {
        this.toplay = false
        this.torestart = false
        this.play = !this.play
        this.restart = !this.restart
      },300)
      
    }
  }
};
</script>
<style lang="less" scoped>
  .fp-scroller{
    overflow:hidden;
  }
  .home_div {
    margin: 0 auto;
    // max-width: 1920px;
    min-width: 1280px;
    position: relative;
    font-size: 0.175rem;
    // background-color: white;
    .home_video{
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      width: 100%;
      background-position: center center;
      background-size: contain;
      object-fit: cover;
    }
    a {
      text-decoration: none;
    }
    .menu {
      position: absolute;
      right: 0;
      top: 4.5rem;
      display: block;
      width: 2.05rem;
      height: 1.825rem;
      li {
        width: 100%;
        overflow: hidden;
        margin-bottom: 0.025rem;
        display: block;
        background-color: black;
        a {
          padding-left: 0.2rem;
          margin-bottom: 0.025rem;
          line-height: 0.4375rem;
          display: block;
          width: 2.05rem;
          height: 0.4375rem;
          color: white;
        }
      }
      .active {
        background-color: #FFF700;
        a {
          color: black;
        }
      }
      li:hover {
         background-color: #FFF700;
         a {
           color: black;
         }
      }
    }
  }
</style>

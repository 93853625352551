<template>
	<div class="navbar">
			<!-- 只能有一个根元素 -->
			<div class="sticky" @click="open">
				<img class="logo" src="~assets/images/logo.png"></img>
				<img class="right" :src="isOpenSrc" :style="{ float: 'right', marginTop: '0.1rem', width: rightWidth, height: rightHeight }"></img>
			</div>
		<div class="mask" :style="{display: maskDisplay}">
			<ul class="mask_ul">
				<li>
					<a @click="open" href="/mobile#page1">
						<img src="~assets/images/m_home.png"></img>
						<span class="text1">{{$t('home.index')}}</span>
					</a>
				</li>
				<li>
					<a @click="open" href="/mobile#page2">
						<img src="~assets/images/m_pro.png"></img>
						<span class="text1">{{$t('home.product')}}</span>
					</a>
				</li>
				<li>
					<a @click="open" href="/mobile#page3">
						<img src="~assets/images/m_third.png"></img>
						<span class="text1">{{$t('home.product_safety_guarantee')}}</span>
					</a>
				</li>
				<li>
					<a @click="open" href="/mobile#page4">
						<img src="~assets/images/m_four.png"></img>
						<span class="text1">{{$t('home.ip')}}</span>
					</a>
				</li>
			</ul>
			<ul class="item_ul">
				<li>
					<img src="~assets/images/paper.png" alt="">
					<router-link to="/m_policy" class="text1">{{$t('footer.privacy_policy')}}</router-link>
				</li>
				<li>
					<img src="~assets/images/paper.png" alt="">
					<router-link to="/m_service" class="text1">{{$t('footer.term_of_service')}}</router-link>
				</li>
				<li>
					<img src="~assets/images/contact.png" alt="">
					<span class="text1">{{$t('footer.contact')}}</span>
				</li>
			</ul>
			<div class="logobox">
				<a href="https://space.bilibili.com/106874236" target="__blank"><img src="~assets/images/bilibili.png" alt=""></a>
				<a href="https://www.douyin.com/" target="__blank"><img src="~assets/images/douyin.png" alt=""></a>
				<img src="~assets/images/wechat.png" alt="">
				<a href="https://weibo.com/x1art/" target="__blank"><img src="~assets/images/weibo_logo.png" alt=""></a>
			</div>
		</div>
	</div>
</template>

<script>
import Open from 'assets/images/open.png'
import Close from 'assets/images/close.png'
	export default {
		name: "navbar",
		data() {
			return {
				isOpenSrc : Open,
				rightWidth: '0.44rem',
				rightHeight: '0.36rem',
				stickyBgColor: "rgba(0,18,42,0.3)",
				cellItemTitleStyle: {
					fontColor: "white",
				},
				maskDisplay: "none"
			};
		},
		computed: {
			
		},
		methods: {
			open () {
				this.isOpenSrc = this.isOpenSrc === Open ? Close : Open
				this.rightWidth = this.rightWidth === '0.44rem' ? '0.34rem' : '0.44rem'
				this.rightHeight = this.rightHeight === '0.36rem' ? '0.34rem' : '0.36rem'
				this.stickyBgColor = this.stickyBgColor === "rgba(0,18,42,0.3)" ? "rgba(26,65,116,1)" : "rgba(0,18,42,0.3)"
				this.maskDisplay = this.maskDisplay === "none" ? "flex" : "none"
			}
		}
	}
</script>

<style lang="less" scoped>
  .navbar{
    padding: 0 !important;
    z-index: 1;
		min-width: 320px;
		// max-width: 480px;
  }
	.sticky {
		position: relative;
		z-index: 6;
		width: 100%;
    background-color: rgba(0, 0, 0, 0.95);
		height: 0.98rem;
		color: #fff;
		// padding: 0.2rem;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    .logo {
      width: 1.84rem;
      height: 0.74rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .right{
      position: absolute;
      right: 0.2rem;
    }
	}
	.mask {
		overflow: auto;
		flex-wrap: wrap;
		align-content: space-between;
		position: fixed;
    top: 0;
    left: 0;
		z-index: 5;
		opacity: 0.8;
		background-color: black;
		width: 100%;
		height: calc(100vh);
	}
  .mask_ul{
    width: 100%;
    margin: 0 auto;
		margin-top: 0.98rem;
    // padding: 0.24rem 0.5rem 2.34rem 0.5rem;
    padding: 0.24rem 0.5rem 0 0.5rem;
		// border-bottom: 0.04rem solid #FFF700;
    li {
      display: flex; 
      align-items: center;
      list-style:none;
      width: 100%;
      height:1.2rem;
      border-bottom: 0.02rem solid #333333;
      img {
        width: 0.48rem;
        height: 0.48rem;
      }
      .text1 {
        margin-left: 0.12rem;
        font-size: 0.28rem;
        height: 0.39rem;
        line-height: 0.38rem;
        display: inline-block;
        color: white;
        font-weight:bold;
      }
    }
		li:nth-child(4) {
			border-bottom: none;
		}
  }
	.item_ul {
		width: 100%;
    margin: 0 auto;
    padding: 0.24rem 0.5rem 0 0.5rem;
		border-top: 0.04rem solid #FFF700;
		li{
			display: flex; 
      align-items: center;
      list-style:none;
      width: 100%;
      height:1.2rem;
      border-bottom: 0.02rem solid #333333;
			img {
        width: 0.48rem;
        height: 0.48rem;
      }
      .text1 {
        margin-left: 0.12rem;
        font-size: 0.28rem;
        height: 0.38rem;
        line-height: 0.38rem;
        display: inline-block;
        color: white;
        font-weight:bold;
      }
		}
		li:nth-child(3) {
			border-bottom: none;
		}
	}
	.logobox {
		width: 4.64rem;
		height: 0.8rem;
		margin: 0 auto;
		// margin-top: 0.6rem;
		margin-bottom: 1.72rem;
		display: flex;
		justify-content: space-between;
	}
</style>
